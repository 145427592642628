label.error {
  color: $brand-danger;
}
input.error {
  border-color: $brand-danger;
  &:focus {
    border-color: $brand-danger;
  }
}

/*
BOX INPUT FROM https://css-tricks.com/drag-and-drop-file-uploading/
*/
.box__dragndrop,
.box__uploading,
.box__success,
.box__error {
  display: none;
}
.box.has-advanced-upload .box__wrapper {
  background-color: white;
  outline: 2px dashed $brand-danger ;
  min-height: 200px;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.box.is-dragover .box__wrapper{
  background-color: $brand-info;

}

.box.has-advanced-upload .box__dragndrop {
  display: inline;
}
.box__input {

}
.box.is-uploading .box__input {
  visibility: none;
}
.box.is-uploading .box__uploading {
  display: block;
}
