
.container {
  width: 94%;
  padding: 0 3%;
}

.row {
  margin: 0;
}
.navbar-rightside {
  float: right;
}
.loggedInNav {
  float: left;
  padding: 0 15px;
  li {
    display: inline-flex;
    padding-left: 5px;
    border-left: 1px solid $brand-primary;
    &:first-of-type {
      border-left: none;
    }
  }

}

.monthSelect {
  display: inline-flex;
}
.monthSelect select {
    -moz-appearance: none;
    -webkit-appearance: none;
    border: medium none;
    outline: medium none;
    background-color: white;
}
