

// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import "node_modules/font-awesome/scss/font-awesome";

// Variables
@import "variables";


// Bootstrap
//@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/bootstrap/scss/bootstrap";

@import "components";
@import "layout";
@import "forms";





// Modal
@import "modal";

#ajaxBody {
  position: absolute;
  width: 94%;
  left: 3%;

}

// THUMBNAIL ITEMS
.thumbHolder {
  width: 160px;
  padding: 0 5px;
  margin: auto;
  text-align: center;
}
.thumbItem {
    width: 160px;
    flex: 1 1 auto;
    padding: 0 5px;
    text-align: center;
}

.editOverlay {
  position: absolute;
  width: 150px;
  margin: auto;
  background: $gray-lightest-opacity;
  display: none;
}
.thumbHolder:hover .editOverlay {
  display: block;
}


//BULK ADD items
.bulkform {
  position: relative;;
}

.bulkAddItem {
  width: 200px;
  flex: 1 1 auto;
}


//MESSAGES
.message {
  max-width: 800px;
  width: 100%;
  text-align: center;
  margin: 20px auto;
  padding: 10px;
  border: 2px solid $brand-success-darken;
  background-color: $brand-success-lighten

}

// Bootstrap override
.btn-secondary {
  font-size: .75rem;
  padding: .35rem .75rem;
}

//LOADING
.loadingToggle {
  display: none;
  position: absolute;
  top: 30px;
}

.panel-default > .panel-heading {
  color: $brand-success;
}
