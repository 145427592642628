.modal {
  nav {

    .fa {
      font-size: 3em;
      font-size: 3rem;

    }
    &.closeModal {
      position: absolute;
      right: 5px;
      .fa {
        font-size: 2rem;
        font-size: 2em;
      }
    }
    &.playPause {
      position: absolute;
      right: 5px;
      bottom: 10px;
      .fa {
        font-size: 2rem;
        font-size: 2em;
      }
    }


  }
  .slideImage {
    flex: 1;
    text-align: center;
    max-height: 100%;
  }
}

.modal-dialog {
  max-width: 100%;
  max-height: 100%;
  margin: 0;
}


.modal-body {
  padding: 0;
}

.modal-content {
  background-color: #000000;
}



.slideshowMain {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  nav {
    position: absolute;
    top: 50%;
    &.leftArrow {
      left: 0;
    }
    &.rightArrow {
      right: 0;
    }
  }
  &.slideshowRunning {
    nav {
      display: none;
    }
  }
}
