//Enable Flex
$enable-flex: true;

// Body
$body-bg: #ffffff;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;



$gray-dark:                 #373a3c !default;
$gray:                      #55595c !default;
$gray-light:                #818a91 !default;
$gray-lighter:              #eceeef !default;
$gray-lightest:             #f7f7f9 !default;
$gray-lightest-opacity: rgba(247, 247, 249, .5);



// Brands
$brand-primary: #008D8F;
$brand-info: #8A8D8F;
$brand-success: #399F4A;
$brand-success-lighten: lighten($brand-success, 30%);
$brand-success-darken: darken($brand-success, 30%);
$brand-warning: #B59F4A;
$brand-danger:  #C9522A;



// Typography
$font-family-sans-serif: "Raleway", sans-serif;
//$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #594839;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;
